import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule)
  },   {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'jobs',
    loadChildren: () => import('./jobs/jobs.module').then( m => m.JobsPageModule)
  }, 
  {
    path: 'policy',
    loadChildren: () => import('./modals/policy/policy.module').then( m => m.PolicyPageModule)
  },   {
    path: 'apply',
    loadChildren: () => import('./homeComponents/apply/apply.module').then( m => m.ApplyPageModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('./homeComponents/blog/blog.module').then( m => m.BlogPageModule)
  },
  {
    path: 'copyright',
    loadChildren: () => import('./homeComponents/copyright/copyright.module').then( m => m.CopyrightPageModule)
  },
  {
    path: 'customers',
    loadChildren: () => import('./homeComponents/customers/customers.module').then( m => m.CustomersPageModule)
  },
  {
    path: 'footer',
    loadChildren: () => import('./homeComponents/footer/footer.module').then( m => m.FooterPageModule)
  },
  {
    path: 'getintouch',
    loadChildren: () => import('./homeComponents/getintouch/getintouch.module').then( m => m.GetintouchPageModule)
  },
  {
    path: 'header',
    loadChildren: () => import('./homeComponents/header/header.module').then( m => m.HeaderPageModule)
  },
  {
    path: 'main',
    loadChildren: () => import('./homeComponents/main/main.module').then( m => m.MainPageModule)
  }, 
  {
    path: 'services',
    loadChildren: () => import('./homeComponents/services/services.module').then( m => m.ServicesPageModule)
  },
  {
    path: 'slider',
    loadChildren: () => import('./homeComponents/slider/slider.module').then( m => m.SliderPageModule)
  },


];

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
