export const environment = {
  firebase: {
    projectId: 'pavilion-8a8d7',
    appId: '1:222544538763:web:a4407f2c1e1aae3bbab88e',
    storageBucket: 'pavilion-8a8d7.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyBE537KvFSSYv7l68N6pEHJFEObJDwjn2A',
    authDomain: 'pavilion-8a8d7.firebaseapp.com',
    messagingSenderId: '222544538763',
    measurementId: 'G-RM50YHYL61',
  },
  production: false
};
